import {Component, Input, OnChanges} from '@angular/core';

@Component({
    selector: '[app-illustration-tree]',
    template: `
        <svg [attr.width]="w" [attr.height]="h" [attr.viewBox]="'0 0 ' + w + ' ' + h" [attr.x]="x" [attr.y]="y">
            <rect [attr.x]="(w - trunkW) / 2" [attr.y]="crownR" [attr.width]="trunkW" [attr.height]="trunkH" [attr.class]="'fill-' + colorClasses.trunk"></rect>
            <circle [attr.cx]="w / 2" [attr.cy]="crownR" [attr.r]="crownR" [attr.class]="'fill-' + colorClasses.crown"></circle>
        </svg>
    `,
})
export class IllustrationTreeComponent implements OnChanges {
    @Input() left: number;
    @Input() right: number;
    @Input() top: number;
    @Input() bottom: number;
    @Input() trunkW = 25;
    @Input() mTrunkH = 3; // trunk height multiplier
    @Input() mCrownR = 2; // crown radius multiplier
    @Input() colorClasses: {full?: string; trunk?: string; crown?: string} = {};

    trunkH: number;
    crownR: number;
    w: number;
    h: number;
    x: number;
    y: number;

    ngOnChanges() {
        this.trunkH = this.trunkW * this.mTrunkH;
        this.crownR = this.trunkW * this.mCrownR;
        this.w = this.crownR * 2;
        this.h = this.trunkH + this.crownR;
        this.x = typeof this.left === 'number' ? this.left : typeof this.right === 'number' ? this.right - this.w : null;
        this.y = typeof this.top === 'number' ? this.top : typeof this.bottom === 'number' ? this.bottom - this.h : null;
        this.colorClasses = {trunk: this.colorClasses.full || 'primary', crown: this.colorClasses.full || 'brand-3', ...this.colorClasses};
    }
}
