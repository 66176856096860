import {Component, Input, OnChanges, ViewEncapsulation} from '@angular/core';
import {faBuilding as farBuilding} from '../../@theme/fortawesome/pro-regular-svg-icons';
import {faBuilding as fasBuilding} from '../../@theme/fortawesome/pro-solid-svg-icons';
import {animate, style, transition, trigger} from '@angular/animations';
import {longTransition, transitionDurations, transitionEasing, transitionEasingElastic} from '../../@theme/animations';
import {toCamelCase} from '../../utils/string.utils';

import {far} from '../../@theme/components/fa/far.library';
import {fas} from '../../@theme/components/fa/fas.library';
import {contextColors} from '../../@theme/theme-colors';

export function getJumpInTrigger(timeout: number) {
    return trigger(`jumpIn${timeout ? `_${timeout}` : ''}`, [
        transition(':enter', [
            style({transform: 'translateY(120px)', opacity: '0'}),
            animate(`${transitionDurations.long} ${timeout || 0}ms ${transitionEasing}`),
        ]),
        transition(':leave', [
            animate(longTransition, style({transform: 'translateY(50px)'})),
        ]),
    ]);
}

export function getJumpInLongTrigger(timeout: number) {
    return trigger(`jumpInLong${timeout ? `_${timeout}` : ''}`, [
        transition(':enter', [
            style({transform: 'translateY(120px) scale(.8)', opacity: '0'}),
            animate(`${transitionDurations.longer} ${timeout || 0}ms ${transitionEasing}`),
        ]),
        transition(':leave', [
            animate(longTransition, style({transform: 'translateY(50px)'})),
        ]),
    ]);
}

@Component({
    selector: 'app-illustration',
    template: `
        <svg [attr.width]="w" [attr.height]="h" [attr.viewBox]="'0 0 ' + w + ' ' + h" class="illustration-svg">
            <g clip-path="url(#mask)">
                <circle [@scaleIn]
                        [attr.cx]="circle.cx"
                        [attr.cy]="circle.cy"
                        [attr.r]="circle.r"
                        [style.fill]="circleColor[0]"
                        [style.opacity]="circleColor[1]"
                        [class.pulsating]="pulsating">
                </circle>
                <ng-container *ngIf="hasTrees">
                    <g app-illustration-tree [@jumpIn_300]
                       [left]="0" [bottom]="baseline"
                       [mTrunkH]="2.05" [mCrownR]="1.55"
                       [colorClasses]="{crown: 'brand-3-darker'}"></g>
                    <g app-illustration-tree [@jumpIn_200]
                       [left]="53" [bottom]="baseline"
                       [mTrunkH]="2.4" [mCrownR]="1.8"></g>
                </ng-container>
                <g [@jumpInLong_300] style="transform-origin: 50% 100%">
                    <svg [attr.viewBox]="getFaViewBox(icon.fas)" [attr.height]="iconH" [attr.y]="baseline - iconH">
                        <path [attr.d]="getFaPath(icon.fas)" class="fill-white"></path>
                    </svg>
                </g>
                <g [@jumpInLong_100] style="transform-origin: 50% 100%">
                    <svg [attr.viewBox]="getFaViewBox(icon.fas)" [attr.height]="iconH" [attr.y]="baseline - iconH">
                        <path [attr.d]="getFaPath(icon.far)" class="fill-primary"></path>
                    </svg>
                </g>
                <ng-container *ngIf="hasTrees">
                    <g app-illustration-tree [@jumpIn_350]
                       [right]="w" [bottom]="baseline"
                       [colorClasses]="{crown: 'brand-3-darker'}"
                       [mTrunkH]="2.6" [mCrownR]="1.85"></g>
                    <g app-illustration-tree [@jumpIn_250]
                       [right]="w - 57" [bottom]="baseline"
                       [mTrunkH]="3.2" [mCrownR]="2.1"></g>
                </ng-container>
            </g>
            <clipPath id="mask">
                <rect [attr.x]="0" [attr.y]="0" [attr.width]="w" [attr.height]="baseline" class="fill-primary"></rect>
            </clipPath>
        </svg>
    `,
    styleUrls: ['./illustration.component.scss'],
    animations: [
        getJumpInTrigger(0),
        getJumpInTrigger(50),
        getJumpInTrigger(100),
        getJumpInTrigger(150),
        getJumpInTrigger(200),
        getJumpInTrigger(250),
        getJumpInTrigger(300),
        getJumpInTrigger(350),
        getJumpInLongTrigger(0),
        getJumpInLongTrigger(50),
        getJumpInLongTrigger(100),
        getJumpInLongTrigger(150),
        getJumpInLongTrigger(200),
        getJumpInLongTrigger(250),
        getJumpInLongTrigger(300),
        getJumpInLongTrigger(350),
        trigger('scaleIn', [
            transition(':enter', [
                style({transform: 'scale(0)', opacity: '0'}),
                animate(`1200ms ${transitionEasingElastic}`),

            ]),
            transition(':leave', [
                animate(longTransition, style({})),
            ]),
        ]),
    ],
    encapsulation: ViewEncapsulation.None,
})
export class IllustrationComponent implements OnChanges {
    @Input() iconName: string;
    @Input() icon = {far: farBuilding, fas: fasBuilding};
    @Input() hasTrees = false;
    @Input() circleColor = [contextColors.primary, .15];
    @Input() pulsating = false;

    w = 500;
    h = 375;
    circle = {
        cx: this.w / 2,
        cy: this.h * .57,
        r: this.w * .33,
    };
    baseline = this.h * .85;
    iconH = this.w * .515;

    ngOnChanges() {
        if (this.iconName) {
            const fullName = toCamelCase(`fa-${this.iconName}`);
            this.icon = {far: far[fullName], fas: fas[fullName]};
        }

        this.icon = {...{far: farBuilding, fas: fasBuilding}, ...this.icon};
    }

    getFaPath(faDefinition) {
        return faDefinition.icon[4];
    }

    getFaViewBox(faDefinition) {
        return `0 0 ${faDefinition.icon.slice(0, 2).join(' ')}`;
    }
}
